<div
    *transloco="let t"
    [class]="'flex w-full ' + (buttonRowClasses ?? '')"
    [ngClass]="{
        'flex-col sm:flex-row': options.length > breakOnLimit,
    }"
    role="radiogroup"
    [attr.aria-labelledby]="radiogroupId">
    @for (option of options; track $index; let isFirst = $first; let isLast = $last) {
        <label
            tabindex="0"
            (keydown.Space)="onKeyInput($event, option.value)"
            (keydown.enter)="onKeyInput($event, option.value)"
            [ngClass]="{
                'bg-white text-gray-100 border-gray-300': formControl.value !== option.value,
                'bg-primary text-white border-primary': formControl.value === option.value,
                '!border-danger': !isValid && showValidation,
                '!border-success': isValid && showValidation,
                'focus:ring-danger/25': !isValid && showValidation,
                'focus:ring-success/25': isValid && showValidation,
                'z-[1]': formControl.value !== option.value,
                '-mx-px first:rounded-l-md last:rounded-r-md': options.length <= breakOnLimit,
                '-my-px sm:-mx-px sm:first:ml-0 sm:last:mr-0': options.length > breakOnLimit,
                'rounded-t-md sm:rounded-tr-none sm:rounded-l-md': isFirst && options.length > breakOnLimit,
                'rounded-b-md sm:rounded-bl-none sm:rounded-r-md': isLast && options.length > breakOnLimit,
            }"
            class="flex-1 focus:ring-input block p-3.5 text-center focus:z-[2] border-2 cursor-pointer select-none outline-none">
            {{ t(option.label) }}
            <input
                tabindex="-1"
                type="radio"
                [attr.name]="name || formControlName"
                [name]="name"
                #radioInput
                [value]="option.value"
                [formControl]="formControl"
                [required]="isRequired"
                [checked]="formControl.value === option.value"
                (click)="onChange(option.value)"
                class="sr-only" />
        </label>
    }
</div>
