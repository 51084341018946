import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseControlValueAccessor } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { type ButtonGroupDataModel } from '../models';
@Component({
    selector: 'big-ui-button-group',
    standalone: true,
    imports: [NgClass, FormsModule, ReactiveFormsModule, TranslocoDirective],
    templateUrl: './button-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ButtonGroupComponent,
            multi: true,
        },
    ],
})
export class ButtonGroupComponent<T> extends BaseControlValueAccessor<T> {
    // the amount of options when the mode will switch to flex direction column on mobile devices
    public readonly breakOnLimit = 2;

    @Input() public isRequired = false;
    @Input() public label?: string;
    @Input() public showValidation = false;
    @Input() public isValid = true;
    @Input() public isTouched = false;
    @Input() public options: ButtonGroupDataModel<T>[] = [];
    @Input() public name = '';
    @Input() public formControlName?: string;
    @Input() public buttonRowClasses?: string;
    @Input() public radiogroupId = '';

    public onKeyInput(event: KeyboardEvent, value: T): void {
        event.preventDefault();

        this.writeValue(value);
        this.onChange(value);
    }
}
